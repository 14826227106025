<template>
  <audio-recorder
    :attempts="1"
    :after-recording="stopRecording"
    :show-upload-button="false"
    :show-download-button="false"
  />
</template>

<script>
export default {
  name: "my-recorder",
  data() {
    return {};
  },

  components: {},

  methods: {
    stopRecording(data) {
      this.$emit("update", {
        blob: data.blob,
        url: data.url,
      });

      setTimeout(() => {
        document.querySelector(".ar-records__record").click();

        setTimeout(() => {
          document
            .querySelector(".ar-records__record .ar__rm")
            .addEventListener("click", () => {
              this.removeAudio();
            });
        }, 0);
      }, 0);
    },

    removeAudio() {
      this.$emit("update", {});
    },
  },

  mounted() {},
};
</script>
